.empty-state-img {
    width: 127px;
}

.empty-state-title {
    font-size: 16px;
    font-weight: 600;
}

.empty-state-subtext {
    font-size: 14px;
    font-weight: 400;
}