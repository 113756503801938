@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

.login-background {
  background-image: url("../images/login/background.png");
  min-height: 100vh;; 
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; 
  background-attachment: fixed;
}

.dnd-login-background {
  background-image: url("../images/login/dnd-background.jpg");
  min-height: 100vh;; 
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; 
  background-attachment: fixed;
}

.viewUserGuide {
  position:absolute;
  top: 100px;
  right:40px;
  color:#F8F9FA;
}
.vertical-center {
  align-content: center;
  min-height: 100vh;
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.apex-charts {
  min-height: 10px !important;
}
// .noti-icon .badge {
//   left: 23px;
// }
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1002 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Crypto enteries
.bs-select select {
  width: auto !important;
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  // .control-dots {
  //   margin-top: 35px !important;
  // }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}
//caldendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}
@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}

// calendar modal
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.wizard .actions {
  position: relative !important;
  display: block !important;
  text-align: right !important;
  width: 100% !important;
}
.wizard .actions > ul {
  display: block !important;
  text-align: right !important;
  padding-left: 0 !important;
}
.wizard .actions > ul > li {
  display: inline-block !important;
  margin: 0 0.5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}
.dropzone:focus {
  outline: none !important ;
}
//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}

.primary-bgcolor {
  background-color: #7a6fbe !important;
}

.groupTitle {
  font-size: 19px;
  font-weight: 600;
}

.subTitle {
  font-size: 13px;
  font-weight: 600;
}

.subTitleLight {
  font-size: 12px;
  font-weight: 200;
}

.subTitleLightPrimary {
  font-size: 12px;
  font-weight: 200;
  color:#7a6fbe;
}

.rowMarginTop {
  margin-top: 20px;
}

.scrollview {
  max-height: calc(75vh);
  overflow-y: auto;
}

.dealImageBig {
  height: 211px;
  object-fit: contain;
  border-radius: 5px;
}

.dealImageSmall {
  width: 100px;
  height: 75px;
  border-radius: 5px; 
}


.merchantLogo {
  width: 100px;

}

.merchantBanner {
  width: 375px;
  height: 230px
}


.boxStripe {
  padding-inline: 70px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
  
  // min-height: 300px;
  width: 22vw;
  margin: 100px auto;
  background: white;
  border-radius: 5px;
}

.boxStripeDashboard {
  // padding-inline: 70px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
  
  // min-height: 300px;
  // width: 22vw;
  // margin: 100px auto;
  background: white;
  border-radius: 5px;
}

.blueBg {
  background-color: #29bbe3 !important;
}

.greenBg {
  background-color: #58db83 !important;
}

.purpleBg {
  background-color: #7a6fbe !important;
}

.whiteBg {
  background-color:white !important;
}

.greyBg {
  background-color: #e9ecef !important
}

.oldPlanImage {
  background-color: transparent;
  border: none;
}

.plan-card-height {
  min-height: 640px;
}

.faq-section-margin {
  margin-top: 20px;
}

.fontWeightLight {
  font-weight:400;
}

.subLabel {
  font-weight:400;
  font-size: 11px;
  position: relative;
  margin-top: -5px;
  top: -5px
}

.subLabelWarning {
  color:#EC536C;
  font-weight: 400;
  font-size: 10px;
}

.success {
  color: #58DB83;
}

.danger {
  color: #EC536C;
}

.duplicateWarning {
  color: #EC536C;
  border-radius: 5px;
  border: 3px solid #DEE2E6;
  text-align: center;
  padding:25px
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

// remove mouse scroll
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none ! important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none ! important;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none ! important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield;
}

.invalid-feedback {
  position: absolute;
}

.boxWidget-buttoon {
  background-color: #F8F9FA;
  font-weight: 400;
  border-color: transparent;
  color: black;
}

.info-button {
  background-color: #F8F9FA;
  font-weight: 700;
  border-color: transparent;
  color: black;
  border-radius: 20;
}

.selling-end-date {
  margin-top: 1rem;
}

@media (min-width: 1200px){
  .selling-end-date {
      margin-top: 0px;
  }
}

.redemption-start-date {
  margin-top: 0px;
}

@media (min-width: 1200px){
  .redemption-start-date {
    margin-top: 2.3rem;
  }
}

.total-promo-added {
  height: auto;
}

.total-deal-vouchers {
  margin-top: auto;
}

@media (max-width: 767px){
  .total-deal-vouchers {
    margin-top: 50px;
  }

  .sale-summary-card {
    margin-top: 35px;
  }
  
  .open-stripe-dashboard {
    position: absolute;
    right: -2px;
    top: -100px
  }

  .open-stripe-dashboard-2 {
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    right: -16px;
  }
}

.whiteText {
 color: #F8F9FA;
}


.icon-refresh {
  background-image: url('../images/refresh-icon.png');
  background-repeat: no-repeat;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 11pt;
  top: 0pt;
  bottom: 0pt;
}


.terms-textarea {
  padding-inline-start: 18px;
}

.sale-summary-card {
  padding: 0px;
}

.open-stripe-dashboard {
  position: absolute;
}

@media (min-width: 768px){  
  .open-stripe-dashboard {
    position: absolute;
    right: -2px;
    top: -65px
  }

  .open-stripe-dashboard-2 {
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    right: -16px;
  }
}

.apexcharts-bar-area{
  fill: #E4E2F2;
}

.apexcharts-bar-area:hover {
  fill: $primary;
  opacity: 1;
}

.apexcharts-menu-item.exportCSV {
  display: none;
}

.apexcharts-tooltip-title {
  background: #E4E2F2;
}

.apexcharts-tooltip {
  background: white;
  font-size: small;
}

.custom-tooltip {
  background: #E4E2F2;
  display: flex;
  flex-direction: column;
  font-size: 0.5rem;
}

.custom-tooltip-title {
  padding: 2%;
}


.custom-tooltip-info {
  background: white;
  padding-right: 20px;
  padding-left: 2%;
  padding-top: 2%;
}

.url-label {
  font-size: 11px;
  width: 60%;
}

.tooltip { z-index: 1151 !important; }

.tooltip-inner {
  max-width: none !important;
}

.mdb-datatable-filter input {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px;
}

.pagination .page-item.active .page-link {
  background-color: #7a6fbe !important; /* Change to your desired color */
  color: #fff; /* Change to your desired color */
}

.dataTable tbody tr:hover {
  background-color: #7a6fbe !important; /* Change to your desired color */
}

.btn-primary {
  background-color: #7a6fbe !important;
}

