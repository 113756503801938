// 
// _table.scss
// 


.table {
  th {
      font-weight: $font-weight-bold;
  }
  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
      
  }
}


.table>:not(caption)>*>*{
  border-bottom-width: 0px;
  border-top-width: $table-border-width;;
}

.table>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-border-color;
}

.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

// table card list

.table-card-list{
  border-collapse:separate; 
  border-spacing:0 12px; 
  tr{
    background-color: $card-bg;
  }
}


.centerColumnText {
  text-align: center;
  width: 90px;
  padding: 0
}

.verticalAlignHeader {
  vertical-align: middle;
  padding: 4px !important
}

div.outlets div[data-test="datatable-search"] {
  position: relative;
  right: 90px;
  bottom: 10px
}

div.promotions div[data-test="datatable-search"] {
  position: relative;
  right: 110px;
  bottom: 10px
}

div.sellVouchers div[data-test="datatable-search"] {
  position: relative;
  right: 259px;
  bottom: 10px
}

div.salesSummary div[data-test="datatable-search"] {
  position: relative;
  right: 175px;
  bottom: 0.5vh;
}

div.transactionsSummary div[data-test="datatable-search"] {
  position: relative;
  right: 175px;
  bottom: 0.5vh;
}

div.promotions .dataTable td {
  padding: 4px
}

@media (max-width: 768px){
  div.salesSummary div[data-test="datatable-search"] {
    position: relative;
    right: 175px;
    bottom: 3.7vh
  }

  div.transactionsSummary div[data-test="datatable-search"] {
    position: relative;
    right: 175px;
    bottom: 3.7vh;
  }
}

@media (max-width: 500px){
  div.sellVouchers div[data-test="datatable-search"] {
    right: 0px;
    bottom: 45px;
  }
  div.promotions div[data-test="datatable-search"] {
    position: relative;
    right: 0px;
    bottom: 45px
  }
  div.outlets div[data-test="datatable-search"] {
    position: relative;
    right: 0px;
    bottom: 45px
  }
  div.salesSummary div[data-test="datatable-search"] {
    position: relative;
    right: 0px;
    bottom: 45px
  }
  div.transactionsSummary div[data-test="datatable-search"] {
    position: relative;
    right: 0px;
    bottom: 0.5vh;
  }
}