

/* 
Datatable
*/

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-fixed {
  table-layout: fixed;
}

div.dataTables_wrapper {
  div.dataTables_filter{
    text-align: right;
    input{
      margin-left: 0.5em;
      margin-right: 0;
    }
  }
}

table.dataTable th.width10 {
  width: 10px;
}

table.dataTable th.width20 {
  width: 20px;
}

table.dataTable th.width30 {
  width: 30px;
}

table.dataTable th.width50 {
  width: 50px;
}

table.dataTable th.width80 {
  width: 80px;
}

table.dataTable th.width100 {
  width: 100px;
}

table.dataTable th.width150 {
  width: 150px;
}

table.dataTable th.width180 {
  width: 180px;
}

table.dataTable th.width200 {
  width: 200px;
}

div.promotions table.dataTable td:nth-child(2), 
div.promotions table.dataTable td:nth-child(3),
div.promotions table.dataTable td:nth-child(4) {
  text-align: center
}


div.outlets table.dataTable tbody td:nth-child(2),
div.outlets table.dataTable tbody td:nth-child(3),
div.outlets table.dataTable tbody td:nth-child(5)  {
  text-align: center;
}

div.salesSummary table.dataTable tbody td:nth-child(1),
div.salesSummary table.dataTable tbody td:nth-child(2),
div.salesSummary table.dataTable tbody td:nth-child(3),
div.salesSummary table.dataTable tbody td:nth-child(4),
div.salesSummary table.dataTable tbody td:nth-child(5),
div.salesSummary table.dataTable tbody td:nth-child(6)
 {
  text-align: center;
}

div.salesSummary div.mdb-datatable-filter  {
  z-index: 100;
}

div.transactionsSummary table.dataTable tbody td {
  text-align: center;
}

div.transactionsSummary div.mdb-datatable-filter  {
  z-index: 100;
}


div.transactionsSummary div.dataTables_length.bs-select{
  z-index: 100;
}